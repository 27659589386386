<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์รายงานภาษี</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber, formatFloat} from "./assets/utils";

  export default {
    props: ['staff', 'bookLists', 'org', 'startDate', 'endDate'],
    components: {
      jsPDF,
    },
    name: "P32Day",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)',
        area: '04'
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      generatePDF() {
        let fileName = 'รายงาน ป.32 ประจำวัน';
        const title = this.staff;
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        const bookLists = this.bookLists;
        const org = this.org;
        const dateStart = moment(this.startDate).format('DD/MM/YYYY');
        const dateStartSplit = dateStart.split('/');
        const startYear = parseInt(dateStartSplit[2]) + 543;
        const startDate = dateStartSplit[0] + '/' + dateStartSplit[1] + '/' + startYear;
        const dateEnd = moment(this.endDate).format('DD/MM/YYYY');
        const dateEndSplit = dateEnd.split('/');
        const endYear = parseInt(dateEndSplit[2]) + 543;
        const endDate = dateEndSplit[0] + '/' + dateEndSplit[1] + '/' + endYear;
        const date = this.currentThaiDate()
        const area = this.area;
        const nameVillageNo = this.nameVillageNo;
        let BA11Sum = 0;
        let BA12Sum = 0;
        let BA13Sum = 0;
        let BA14Sum = 0;
        let BA15Sum = 0;
        let allSum = 0;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          const date = bookLists[i].B24;
          let vat = 0.0
          if(parseFloat(bookLists[i].BA13) !== 0){
            vat = (formatFloat(bookLists[i].BA11) + (formatFloat(bookLists[i].BA12))) * 0.07
          }
          const sumBA = parseFloat(bookLists[i].BA11) +
            parseFloat(bookLists[i].BA12) +
            vat +
            parseFloat(bookLists[i].BA14) +
            parseFloat(bookLists[i].BA15);
          array.push([
            i + 1,
            date,
            bookLists[i].B02,
            bookLists[i].M04,
            {content: formatNumber(sumBA) , styles: { halign: 'right'}},
            {content: formatNumber(vat) , styles: { halign: 'right'}},
          ]);
          BA11Sum += parseFloat(bookLists[i].BA11);
          BA12Sum += parseFloat(bookLists[i].BA12);
          BA13Sum += vat;
          BA14Sum += parseFloat(bookLists[i].BA14);
          BA15Sum += parseFloat(bookLists[i].BA15);
          allSum += parseFloat(bookLists[i].sum);
        }
        let sumBA111213 = BA11Sum + BA12Sum + BA13Sum + BA14Sum + BA15Sum;
        array.push([
          ' ',
          ' ',
          ' ',
          'รวม',
          {content: formatNumber(sumBA111213) , styles: { halign: 'right'}},
          {content: formatNumber(BA13Sum) , styles: { halign: 'right'}},
        ]);
        // let text = [1, 2];
        // for (let i = 0; i < bookLists.length; i++) {
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(12);
        // doc.text('พื้นที่ 04 หมู่ที่ 4 บ้านเหล่าคราม', 10, 37);
        doc.autoTable({
          headStyles: {fillColor: [255, 255, 255], textColor: 0, lineColor: 180, lineWidth: 0.1 , fontSize: 12 , halign: 'center'},
          styles: {font: 'THSarabunNew', fontSize: 12},
          margin: {left: 8, top: 50, bottom: 20},
          theme: 'grid',
          head: [['ลำดับที่', 'วัน เดือน ปี', 'เลขที่', 'ชื่อสินค้า/ผู้ให้บริการ', 'มูลค่าสินค้าหรือบริการ', 'จำนวนเงินภาษีมูค่าเพิ่ม']],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(20);
            doc.text('รายงานภาษีขาย', doc.internal.pageSize.getWidth() / 2, 10, {align: 'center', });
            doc.setFontSize(14);
            doc.text('ประจำวันที่ ' + startDate + ' - ' + endDate, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.text('ชื่อสถานประกอบการ    '+org.Org01, 25, 30, {align: 'left'});
            doc.text('เลขประจำตัวผู้เสียภาษีอากร', 130, 30, {align: 'left'});
            doc.text(org.tax_id != null ? org.tax_id : '', 130, 38, {align: 'left'});
            doc.setFontSize(16);
            },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save('รายงานภาษีประจำวัน-' + moment().format('DD-MM-YYYY') + '.pdf');
        // doc.setProperties({
        //   title: `report${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
      }
    }
  }
</script>

