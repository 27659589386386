<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">รายงาน ป.32 เรียงตามเลขที่ใบเสร็จ</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber} from "./assets/utils";

  export default {
    props: ['staff', 'bookLists', 'org', 'startDate', 'endDate'],
    components: {
      jsPDF,
    },
    name: "P32Day",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)',
        area: '04'
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      generatePDF() {
        let fileName = 'รายงาน ป.32 ประจำวัน';
        const title = this.staff;
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        const bookLists = this.bookLists.sort((a, b) => parseInt(a['B02']) - parseInt(b['B02']));
        const org = this.org;
        const dateStart = moment(this.startDate).format('DD/MM/YYYY');
        const dateStartSplit = dateStart.split('/');
        const startYear = parseInt(dateStartSplit[2]) + 543;
        const startDate = dateStartSplit[0] + '/' + dateStartSplit[1] + '/' + startYear;
        const dateEnd = moment(this.endDate).format('DD/MM/YYYY');
        const dateEndSplit = dateEnd.split('/');
        const endYear = parseInt(dateEndSplit[2]) + 543;
        const endDate = dateEndSplit[0] + '/' + dateEndSplit[1] + '/' + endYear;
        const date = this.currentThaiDate()
        const area = this.area;
        const nameVillageNo = this.nameVillageNo;
        let BA11Sum = 0;
        let BA12Sum = 0;
        let BA13Sum = 0;
        let BA14Sum = 0;
        let BA15Sum = 0;
        let allSum = 0;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          let vat = 0.0
          if(parseFloat(bookLists[i].BA13) !== 0){
            vat = (parseFloat(formatNumber(bookLists[i].BA11)) + parseFloat(formatNumber(bookLists[i].BA12))) * 0.07
          }
          const date = bookLists[i].B24;
          const sumBA = parseFloat(bookLists[i].BA11) +
            parseFloat(bookLists[i].BA12) +
            vat +
            parseFloat(bookLists[i].BA14) +
            parseFloat(bookLists[i].BA15);
          array.push([
            i + 1,
            date,
            bookLists[i].B03,
            bookLists[i].M02,
            bookLists[i].CB04,
            formatNumber2Digit(bookLists[i].BA11),
            formatNumber2Digit(bookLists[i].BA12),
            formatNumber2Digit(vat),
            formatNumber2Digit(bookLists[i].BA14),
            formatNumber2Digit(bookLists[i].BA15),
            formatNumber(sumBA),
            bookLists[i].B02
          ]);
          BA11Sum += parseFloat(bookLists[i].BA11);
          BA12Sum += parseFloat(bookLists[i].BA12);
          BA13Sum += vat;
          BA14Sum += parseFloat(bookLists[i].BA14);
          BA15Sum += parseFloat(bookLists[i].BA15);
          allSum += parseFloat(bookLists[i].sum);
        }
        let sumBA111213 = BA11Sum + BA12Sum + BA13Sum + BA14Sum + BA15Sum;
        let sumBA = BA11Sum + BA12Sum + BA13Sum;
        array.push([
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          formatNumber2Digit(BA11Sum),
          formatNumber2Digit(BA12Sum),
          formatNumber2Digit(BA13Sum),
          formatNumber2Digit(BA14Sum),
          formatNumber2Digit(BA15Sum),
          formatNumber(sumBA111213),
          ' '
        ]);
        array.push([' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']);
        array.push([{
          content: '.....................................',
          colSpan: 4,
          rowSpan: 7,
          styles: {valign: 'bottom', halign: 'center'}
        }, {content: 'ได้รับชำระบิล', colSpan: 5}, {content: bookLists.length + ' ฉบับ', styles: {halign: 'right'}}]);
        array.push([{
          content: 'ได้รับเงินค่าน้ำ(ค่าน้ำ + ค่าธรรมเนียม + ภาษี)',
          colSpan: 5
        }, {
          content: formatNumber(sumBA) + ' บาท',
          styles: {halign: 'right'}
        }]);
        array.push([{
          content: 'ได้รับเงินค่าบำบัดน้ำเสีย',
          colSpan: 5
        }, {
          content: formatNumber(BA14Sum) + ' บาท',
          styles: {halign: 'right'}
        }]);
        array.push([{
          content: 'ได้รับเงินค่ากำจัดขยะ',
          colSpan: 5
        }, {
          content: formatNumber(BA15Sum) + ' บาท',
          styles: {halign: 'right'}
        }]);
        array.push([{
          content: 'ได้รับเงินรวมจำนวน',
          colSpan: 5
        }, {
          content: formatNumber(sumBA111213) + ' บาท',
          styles: {halign: 'right'}
        }]);
        array.push([{content: ' ', colSpan: 6}]);
        array.push([{content: ' ', colSpan: 5}, {
          content: '.....................................',
          colSpan: 4,
          styles: {halign: 'center'}
        }]);
        array.push([{
          content: '(.....................................)',
          colSpan: 4,
          styles: {halign: 'center'}
        }, {content: ' ', colSpan: 5}, {
          content: '(.....................................)',
          colSpan: 4,
          styles: {halign: 'center'}
        }]);
        array.push([{content: 'ผู้ส่งเงิน', colSpan: 3, styles: {halign: 'center'}}, {
          content: ' ',
          colSpan: 6
        }, {content: 'ผู้รับเงิน', colSpan: 10, styles: {halign: 'center'}}]);
        // let text = [1, 2];
        // for (let i = 0; i < bookLists.length; i++) {
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(12);
        // doc.text('พื้นที่ 04 หมู่ที่ 4 บ้านเหล่าคราม', 10, 37);
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 12},
          margin: {left: 8, top: 40, bottom: 20},
          theme: 'plain',
          head: [['ลำดับที่', 'วันที่ชำระเงิน', 'รหัสผู้ใช้น้ำ', 'เขต', 'รอบบิล', 'ค่าน้ำ', 'ค่าธรรมเนียม', 'ภาษี', 'ค่าบำบัดฯ', 'ค่าขยะ', 'รวมทั้งสิ้น', 'เลขที่ใบเสร็จ']],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(18);
            doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.setFontSize(16);
            doc.text('บัญชีประจำตัวผู้เก็บเงิน ป.32 ประจำวันที่ ' + startDate + ' - ' + endDate, doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
            if (title !== 'ทั้งหมด') {
              doc.text('พนักงานเก็บเงิน ' + title, doc.internal.pageSize.getWidth() / 2, 36, {align: 'center'});
            }
          },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save('รายงานป.32ประจำวัน-' + moment().format('DD-MM-YYYY') + '.pdf');
        // doc.setProperties({
        //   title: `report${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
      }
    }
  }
</script>

