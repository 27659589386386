<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์ใบเสร็จที่เลือก(เล็ก)</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import BAHTTEXT from "./assets/BAHTTEXT";
  import {formatNumber} from "./assets/utils";
  export default {
    name: "BillReport",
    components: {
      jsPDF,
    },
    props: ['yearTh', 'bookLists', 'imgBase64', 'imgLogoBase64', 'org'],
    data() {
      return {
        // 5 บรรทัด
        left: {x: 33, y: 12},
        center: {x: 105, y: 12},
        right: {x: 178, y: 12},
        signature: {x: 0, y: 0},
        spacing: 94,
      }
    },
    methods: {
      date(value) {
        let word = value.split(' ');
        return word[0];
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.warningDialog();
        } else {
          this.generatePDF();
        }
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'คุณไม่ได้เลือกใบเสร็จที่ต้องการพิมพ์',
          acceptText: 'ปิด'
        })
      },
      getTaxId(tax_id) {
        if(tax_id.toString().length === 13){
          return tax_id;
        }else{
          return tax_id.substring(0, 13);
        }

      },
      generatePDF() {
        // this.$vs.loading();
        let fileName = 'รายงานใบเสร็จค่าน้ำประปา';
        const yearTh = this.yearTh;
        const bookLists = this.bookLists;
        const baseUrl = 'http://167.99.67.13/';
        const signature = this.imgBase64;
        const logo = this.imgLogoBase64;
        const org = this.org;
        // const doc = new jsPDF({ format: 'a3', orientation: 'landscape' });
        // const doc = new jsPDF('l', 'mm', [330, 210]);
        let doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        if(bookLists.length === 1){
          doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [210, 93]
          });
        }
        if(bookLists.length === 2){
          doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [210, 186]
          });
        }
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'normal');
        doc.setFontSize(14);
        let j = 0;
        for (let i = 0; i < bookLists.length; i++) {
          let line = j;
          //LEFT
          doc.setFontSize(14);
          doc.text(org.Org01, this.left.x, (this.left.y + j * this.spacing) - line);
          doc.setFontSize(12);
          doc.text(org.Org03, this.left.x, (this.left.y + j * this.spacing) - line + 5);
          doc.text(bookLists[i].B02 === undefined ? '' : bookLists[i].B02.toString(), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].CB04 === undefined ? '' : bookLists[i].CB04.toString(), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].B03 === undefined ? '' : bookLists[i].B03.toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].M04 === undefined ? '' : bookLists[i].M04.toString(), this.left.x - 13, (this.left.y + j * this.spacing) - line + 5 * 4.3);
          doc.text(bookLists[i].M27.toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(bookLists[i].M05 + ' ' + bookLists[i].M06, this.left.x - 13, (this.left.y + j * this.spacing) - line + 5 * 5.3);
          doc.text(bookLists[i].M02.toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 5.5);
          doc.setFontSize(10);
          doc.text(bookLists[i].B17.toString(), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B19.toString(), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 9);
          doc.setFontSize(12);
          doc.text(formatNumber(bookLists[i].BA12.toString()), this.left.x + 5, (this.left.y + j * this.spacing) - line + 5 * 10.2);
          doc.text('ภาษีฯ               '+formatNumber(bookLists[i].BA13.toString()) +'         บาท', this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 11.4);
          doc.text('ค่าขยะฯ           '+formatNumber(bookLists[i].BA15.toString()) +'         บาท', this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 12.4);
          doc.text(BAHTTEXT(bookLists[i].sum.toString()), this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 13.4);
          doc.text(bookLists[i].B18.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B20.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 9);
          doc.text(bookLists[i].B21.toString(), this.left.x + 30, (this.left.y + j * this.spacing) - line + 5 * 8.5);
          doc.text(formatNumber(bookLists[i].BA11), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 8.5);
          doc.setFontSize(16);
          doc.text(bookLists[i].sum.toString(), this.left.x + 35, (this.left.y + j * this.spacing) - line + 5 * 12);
          if(signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG',  this.left.x + 15,(this.left.y + j * this.spacing) - line + 5 * 13.5, 20, 10);
          }
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.left.x + 45,(this.left.y - 6 + j * this.spacing), 15, 15);
          }
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.center.x + 40,(this.left.y - 6 + j * this.spacing), 15, 15);
          }
          //CENTER
          doc.setFontSize(14);
          doc.text(org.Org01, this.center.x -10, (this.center.y + j * this.spacing) - line);
          doc.setFontSize(12);
          doc.text(org.Org03, this.center.x -10, (this.center.y + j * this.spacing) - line + 5);
          doc.text(bookLists[i].B02 === undefined ? '' : bookLists[i].B02.toString(), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 2);
          doc.text(org.tax_id === undefined ? '' : this.getTaxId(org.tax_id.toString()), this.center.x + 20, (this.center.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].CB04 === undefined ? '' : bookLists[i].CB04.toString(), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].B03 === undefined ? '' : bookLists[i].B03.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].M04 === undefined ? '' : bookLists[i].M04.toString(), this.center.x - 13, (this.center.y + j * this.spacing) - line + 5 * 4.3);
          doc.text(bookLists[i].M27 === undefined ? '' : bookLists[i].M27.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(bookLists[i].M05 + ' ' + bookLists[i].M06, this.center.x - 13, (this.center.y + j * this.spacing) - line + 5 * 5.3);
          doc.text(bookLists[i].M02.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 5.5);
          doc.setFontSize(10);
          doc.text(bookLists[i].B17.toString(), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B19.toString(), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 9);
          doc.setFontSize(12);
          doc.text(formatNumber(bookLists[i].BA12.toString()), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 10.2);
          doc.text('ภาษีฯ          '+formatNumber(bookLists[i].BA13.toString()), this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 11.4);
          doc.text('ค่าขยะฯ       '+formatNumber(bookLists[i].BA15.toString()) +'         บาท', this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 12.4);
          doc.text(BAHTTEXT(bookLists[i].sum.toString()), this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 13.4);
          doc.text(bookLists[i].B18.toString(), this.center.x + 15, (this.center.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B20.toString(), this.center.x + 15, (this.center.y + j * this.spacing) - line + 5 * 9);
          doc.text(bookLists[i].B21.toString(), this.center.x + 30, (this.center.y + j * this.spacing) - line + 5 * 8.5);
          doc.text(formatNumber(bookLists[i].BA11), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 8.5);
          doc.setFontSize(16);
          doc.text(bookLists[i].sum.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 12);
          if(signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG',  this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 13.5, 20, 10);
          }
          doc.setFontSize(12);
          doc.text(bookLists[i].B09.toString(),  this.center.x + 35, (this.center.y + j * this.spacing) - line + 5 * 14.5);
          if(bookLists[i].B24 !== undefined)
          {
            doc.text(bookLists[i].B24.toString(),  this.center.x + 35, (this.center.y + j * this.spacing) - line + 5 * 16);
            doc.text('วันที่ '+ bookLists[i].B24, this.left.x + 20, (this.left.y + j * this.spacing) - line + 5 * 2);
          }
          //RIGHT
          doc.setFontSize(12);
          doc.text(bookLists[i].CB04 === undefined ? '' : bookLists[i].CB04.toString().toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].B03 === undefined ? '' : bookLists[i].B03.toString().toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 3.3);
          doc.text(bookLists[i].B22.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(bookLists[i].M04 === undefined ? '' : bookLists[i].M04.toString().toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 5.5);
          doc.text(bookLists[i].M05.toString()+' '+bookLists[i].M06.toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5.5 * 6  - 2);
          doc.setFontSize(16);
          doc.text(bookLists[i].sum.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 12.5);
          doc.setFontSize(12);
          // doc.text(bookLists[i].B24.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 15.5);
          j = j + 1;
          if ((i + 1) % 3 === 0 && i !== 1) {
            if (i + 1 === bookLists.length) break;
            doc.addPage();
            j = 0;
          }
        }
        doc.setProperties({
          title: `report${new Date().toLocaleString()}`
        });
        // doc.output("dataurlnewwindow");
        doc.save('ใบเสร็จ-' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
