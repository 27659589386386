<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>แก้ไขข้อมูล</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div v-if="type !== 'date'" class="p-6">
        <vs-input label="เลขที่ใบเสร็จ" v-model="B02" class="mt-5 w-full" name="bill" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('bill')">กรุณากรอกเลขที่ใบเสร็จ</span>
      </div>
      <div v-if="type !== 'bill'" class="p-6 pt-0 pb-0">
        <div class="mt-5 w-full">
          <p class="text-sm mt-5 w-full">วันที่ชำระเงืน</p>
          <v-select :options="invoiceData" :clearable="false" v-model="selectedDate" name="date" v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('date')">กรุณาเลือกวันที่ชำระเงืน</span>
        </div>
      </div>
      <div v-if="type !== 'bill'" class="p-6">
        <vs-input label="รหัสผ่าน" type="password" v-model="password" class="mt-5 w-full" name="password" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('password')">กรุณากรอกรหัสผ่าน</span>
        <span class="text-danger text-sm" v-show="checkPassword && password !== ''">รหัสผ่านไม่ถูกต้อง</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="confirmEdit">ตกลง</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">ยกเลิก</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import moduleInvoiceDate from "@/store/invoice-date/moduleInvoiceDate.js";
import moduleBookList from "@/store/booklist/moduleBookList.js";
import vSelect from 'vue-select';
import moment from "moment";

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
    org: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  data() {
    return {
      dataId: null,
      B02: '',
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
      selectedDate: { label: '', value: '' },
      password: '',
      orgPassword: '',
      checkPassword: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return;
      let data = JSON.parse(JSON.stringify(this.data));
      this.orgPassword = this.org.password;
      this.dataId = data._id;
      this.B02 = data.B02;
      const dateSplit = data.B24.split('/');
      const year = parseInt(dateSplit[2]) - 543;
      const date = year + '/' + dateSplit[1] + '/' + dateSplit[0];
      this.selectedDate = {
        label: data.B24,
        value: date
      };
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar', null);
        }
      }
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    invoiceData() {
      let options = [];
      this.$store.state.invoiceDate.items.forEach(function (item) {
        const dateNow = moment(item.invoice_date).format('DD/MM/YYYY');
        const dateSplit = dateNow.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        const date = dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        options.push({
          label: date,
          value: moment(item.invoice_date).format('DD/MM/YYYY')
        });
      });
      return options;
    }
  },
  methods: {
    initValues() {
      if(this.data.id) return;
        this.dataId = null;
        this.B02 = ""
    },
    submitData() {
      this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.dialog({
              type: 'confirm',
              color: 'warning',
              title: 'คำเตือน',
              text: 'คุณต้องการเแก้ไขข้อมูลใช่หรือไม่',
              acceptText: 'ต้องการ',
              cancelText: 'ยกเลิก',
              accept: this.confirmEdit
            });
          }
      })
    },
    async confirmEdit() {
      let obj = {};
      if(this.type === 'date'){
        if(this.password !== this.orgPassword) {
          this.checkPassword = true;
          return;
        }
        this.checkPassword = false;
        let dateApi = this.selectedDate.value
        let dateApiSplit = this.selectedDate.value.split('/')
        if(dateApiSplit[0].length === 2){
          dateApi = dateApiSplit[2] + '/' + dateApiSplit[1] + '/' + dateApiSplit[0]
        }
        obj = {
          _id: this.dataId,
          B02: this.B02,
          B08: dateApi,
          B24: dateApi
        };
      }  else{
        obj = {
          _id: this.dataId,
          B02: this.B02,
        };
      }
      if (obj._id !== null) {
        await this.$store.dispatch("bookLists/updateItem", obj).then((res) => {
          if (res.status === 200) {
            this.$emit('closeSidebar', res.data);
            this.password = '';
          }
        })
      }
    }
  },
  async created() {
    if (!moduleInvoiceDate.isRegistered) {
      this.$store.registerModule('invoiceDate', moduleInvoiceDate)
      moduleInvoiceDate.isRegistered = true
    }
    if (!moduleBookList.isRegistered) {
      this.$store.registerModule('bookLists', moduleBookList);
      moduleBookList.isRegistered = true;
    }
    await this.$store.dispatch("invoiceDate/fetchDataListItems")
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
