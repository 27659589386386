<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์รายงาน ป.32 ประจำวัน</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  import moduleBookList from "@/store/booklist/moduleBookList.js";
  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber, formatFloat} from "./assets/utils";

  export default {
    props: ['staff', 'bookLists', 'org', 'startDate', 'endDate'],
    components: {
      jsPDF,
    },
    name: "P32Day",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)',
        area: '04'
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      async printP32(date, text){
        await this.$store.dispatch("bookLists/getBookListByP32Print", {date: date, text: text}).then(res => {})
      },
      generatePDF() {
        let fileName = 'รายงาน ป.32 ประจำวัน';
        const title = this.staff;
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        const bookLists = this.bookLists;
        const org = this.org;
        const dateStart = moment(this.startDate).format('DD/MM/YYYY');
        const dateStartSplit = dateStart.split('/');
        const startYear = parseInt(dateStartSplit[2]) + 543;
        const startDate = dateStartSplit[0] + '/' + dateStartSplit[1] + '/' + startYear;
        const dateEnd = moment(this.endDate).format('DD/MM/YYYY');
        const dateEndSplit = dateEnd.split('/');
        const endYear = parseInt(dateEndSplit[2]) + 543;
        const endDate = dateEndSplit[0] + '/' + dateEndSplit[1] + '/' + endYear;
        const date = this.currentThaiDate()
        const area = this.area;
        const nameVillageNo = this.nameVillageNo;
        let BA11Sum = 0;
        let BA12Sum = 0;
        let BA13Sum = 0;
        let BA14Sum = 0;
        let BA15Sum = 0;
        let allSum = 0;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          const date = bookLists[i].B24;
          let vat = 0.0
          if(parseFloat(bookLists[i].BA13) !== 0){
            vat = (formatFloat(bookLists[i].BA11) + formatFloat(bookLists[i].BA12)) * 0.07
          }
          const sumBA = formatFloat(bookLists[i].BA11) +
                  formatFloat(bookLists[i].BA12) +
            vat +
            parseFloat(bookLists[i].BA14) +
            parseFloat(bookLists[i].BA15);
          array.push([
            i + 1,
            date,
            bookLists[i].B03,
            bookLists[i].M04,
            bookLists[i].M02,
            bookLists[i].CB04,
            formatNumber2Digit(bookLists[i].BA11),
            formatNumber2Digit(bookLists[i].BA12),
            formatNumber2Digit(bookLists[i].BA13),
            formatNumber2Digit(bookLists[i].BA14),
            formatNumber2Digit(bookLists[i].BA15),
            formatNumber(sumBA),
            bookLists[i].B02
          ]);
          BA11Sum += parseFloat(bookLists[i].BA11);
          BA12Sum += parseFloat(bookLists[i].BA12);
          BA13Sum += vat;
          BA14Sum += parseFloat(bookLists[i].BA14);
          BA15Sum += parseFloat(bookLists[i].BA15);
          allSum += parseFloat(bookLists[i].sum);
        }
        let sumBA111213 = BA11Sum + BA12Sum + BA13Sum + BA14Sum + BA15Sum;
        let sumBA = BA11Sum + BA12Sum + BA13Sum;
        array.push([
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          formatNumber2Digit(BA11Sum),
          formatNumber2Digit(BA12Sum),
          formatNumber2Digit(BA13Sum),
          formatNumber2Digit(BA14Sum),
          formatNumber2Digit(BA15Sum),
          {
            content: formatNumber(sumBA111213) + ' บาท',
            colSpan: 2
          },
          ' '
        ]);
        array.push([' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']);
        array.push([{
          content: '.....................................',
          colSpan: 4,
          rowSpan: 7,
          styles: {valign: 'bottom', halign: 'center'}
        }, {content: 'ได้รับชำระบิล', colSpan: 5}, {content: bookLists.length + ' ฉบับ', styles: {halign: 'right'},
          colSpan: 3}]);
        array.push([{
          content: 'ได้รับเงินค่าน้ำ(ค่าน้ำ + ค่าธรรมเนียม + ภาษี)',
          colSpan: 5
        }, {
          content: formatNumber(sumBA) + ' บาท',
          styles: {halign: 'right'},
          colSpan: 3
        }]);
        array.push([{
          content: 'ได้รับเงินค่าบำบัดน้ำเสีย',
          colSpan: 5
        }, {
          content: formatNumber(BA14Sum) + ' บาท',
          styles: {halign: 'right'},
          colSpan: 3
        }]);
        array.push([{
          content: 'ได้รับเงินค่ากำจัดขยะ',
          colSpan: 5
        }, {
          content: formatNumber(BA15Sum) + ' บาท',
          styles: {halign: 'right'},
          colSpan: 3
        }]);
        array.push([{
          content: 'ได้รับเงินรวมจำนวน',
          colSpan: 5
        }, {
          content: formatNumber(sumBA111213) + ' บาท',
          styles: {halign: 'right'},
          colSpan: 3
        }]);
        array.push([{content: ' ', colSpan: 6}]);
        array.push([{content: ' ', colSpan: 5}, {
          content: '.....................................',
          colSpan: 4,
          styles: {halign: 'center'}
        }]);
        array.push([{
          content: '(.....................................)',
          colSpan: 4,
          styles: {halign: 'center'}
        }, {content: ' ', colSpan: 5}, {
          content: '(.....................................)',
          colSpan: 4,
          styles: {halign: 'center'}
        }]);
        array.push([{content: 'ผู้ส่งเงิน', colSpan: 3, styles: {halign: 'center'}}, {
          content: ' ',
          colSpan: 6
        }, {content: 'ผู้รับเงิน', colSpan: 10, styles: {halign: 'center'}}]);
        // let text = [1, 2];
        // for (let i = 0; i < bookLists.length; i++) {
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(12);
        // doc.text('พื้นที่ 04 หมู่ที่ 4 บ้านเหล่าคราม', 10, 37);
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 10},
          margin: {left: 2,right: 6, top: 40, bottom: 20},
          theme: 'plain',
          head: [['ลำดับ', 'วันที่ชำระเงิน', 'รหัส','ชื่อผู้ใช้น้ำ', 'เขต', 'รอบบิล', 'ค่าน้ำ', 'ธรรมเนียม', 'ภาษี', 'บำบัดฯ', 'ขยะ', 'รวม', 'เลขที่ใบเสร็จ']],
          body: array,
          columnStyles: {
            0: {cellWidth: 10},
            1: {cellWidth: 16},
            2: {cellWidth: 16},
            3: {cellWidth: 44},
            4: {cellWidth: 8},
            5: {cellWidth: 16},
            6: {cellWidth: 15},
            7: {cellWidth: 15},
            8: {cellWidth: 10},
            9: {cellWidth: 12},
            10: {cellWidth: 12},
            11: {cellWidth: 15},
            12: {cellWidth: 18},
          },
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(18);
            doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
            doc.setFontSize(16);
            doc.text('บัญชีประจำตัวผู้เก็บเงิน ป.32 ประจำวันที่ ' + startDate + ' - ' + endDate, doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
            if (title !== 'ทั้งหมด') {
              doc.text('พนักงานเก็บเงิน ' + title, doc.internal.pageSize.getWidth() / 2, 36, {align: 'center'});
            }
          },
        });
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        this.printP32(this.startDate,
        `ได้รับชำระบิล ${bookLists.length} ฉบับ\nได้รับเงินรวมจำนวน ${formatNumber(sumBA111213)} บาท`)
        doc.save('รายงานป.32ประจำวัน-' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
    ,
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>

