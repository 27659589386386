<template>
  <div class="vx-row">
    <data-view-sidebar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar($event)"
      :data="sidebarData"
      :org="org"
      :type="type"
    />
    <div class="vx-col w-full mb-base">
      <vx-card class="mb-4">
        <marquee-text class="pl-24" :repeat="20" :duration="50">
          <h3>{{notice}}</h3>
        </marquee-text>
      </vx-card>
      <vx-card class="mb-8">
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <h4>บัญชี/รอบบันทึกค่าบริการ</h4>
          </div>
          <div class="vx-col sm:w-1/2 w-full text-right">
            <vs-button type="filled" @click="submitForm()">ตรวจสอบ</vs-button>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/4 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ลำดับรอบ:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="billConfigs"
                  :value="billConfigs"
                  @input="setBillConfig"
                  :clearable="false"
                  v-model="billConfigs[0]"
                  class="mb-4 md:mb-0"
                />
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/4 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-2/5 w-full">
                <span>รหัสรอบบิล:</span>
              </div>
              <div class="vx-col sm:w-3/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="selectedBillConfig"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/4 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ปีงบประมาณ:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="year" readonly />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>แสดงตั้งแต่รอบ(ขึ้นไป):</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="exceptBill"
                  :value="exceptBill"
                  @input="setExceptAboveBillConfigs"
                  :clearable="false"
                  v-model="selectedExceptAboveBillConfig"
                  class="mb-4 md:mb-0"
                />
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>แสดงตั้งแต่รอบ(ลงไป):</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="exceptBill"
                  :value="exceptBill"
                  @input="setExceptBelowBillConfigs"
                  :clearable="false"
                  v-model="selectedExceptBelowBillConfig"
                  class="mb-4 md:mb-0"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชำระเงินวันที่:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  :language="languages[language]"
                  format="dd MMMM yyyy"
                  v-model="start_date"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ถึง:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  :language="languages[language]"
                  format="dd MMMM yyyy"
                  v-model="end_date"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-1">
          <div class="vx-col md:w-1/3 sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ผู้เก็บเงิน:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="users"
                  :value="users"
                  @input="setUser"
                  :clearable="false"
                  v-model="selectedUser"
                  class="mb-4 md:mb-0"
                />
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span class="mx-3">เลือกพื้นที่/กลุ่ม</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="areas"
                  :value="areas"
                  :clearable="false"
                  v-model="selectedArea"
                />
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>แสดง:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="bills"
                  :value="bills"
                  @input="setBill"
                  :clearable="false"
                  v-model="selectedBill"
                  class="mb-4 md:mb-0"
                />
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card>
        <div class="vx-row mb-5">
          <div class="vx-col mb-5 sm:w-1/2 w-full">
            <h4>รายชื่อผู้ที่ชำระเงินแล้ว</h4>
          </div>
          <div class="vx-col w-full">
            <BillReportXL
              :style="{width: '225px'}"
              :yearTh="yearTh"
              :bookLists="bookLists"
              :imgBase64="imgSignBase64"
              :imgLogoBase64="imgLogoBase64"
              :org="org"
              class="mr-3"
            />
            <PDFBillReport
              :style="{width: '225px'}"
              :yearTh="yearTh"
              :bookLists="bookLists"
              :imgBase64="imgSignBase64"
              :imgLogoBase64="imgLogoBase64"
              :org="org"
              class="mr-3"
            />
            <SingleBillReport
              :style="{width: '225px'}"
              :yearTh="yearTh"
              :bookLists="selected"
              :imgBase64="imgSignBase64"
              :imgLogoBase64="imgLogoBase64"
              :org="org"
              class="mr-3"
            />
            <SingleBillReportXL
              :style="{width: '225px'}"
              :yearTh="yearTh"
              :bookLists="selected"
              :imgBase64="imgSignBase64"
              :imgLogoBase64="imgLogoBase64"
              :org="org"
              class="mr-3"
            />
            <PDFBillReportExcel
              :style="{width: '225px'}"
              :yearTh="yearTh"
              :bookLists="bookLists"
              :imgBase64="imgSignBase64"
              :imgLogoBase64="imgLogoBase64"
              :org="org"
              class="mr-3"
            />
            <P32DayReportSortByBillNo
              :style="{width: '225px'}"
              :staff="selectedUser.label"
              :bookLists="bookLists"
              :org="org"
              :startDate="start_date"
              :endDate="end_date"
              class="mr-3"
            />
          </div>
          <div class="vx-col mt-4 w-full">
            <PDFReport
              :style="{width: '225px'}"
              :staff="selectedUser.label"
              :bookLists="bookLists"
              :org="org"
              :startDate="start_date"
              :endDate="end_date"
              class="mr-3"
            />
            <PDFVatReport
              :style="{width: '225px'}"
              :staff="selectedUser.label"
              :bookLists="bookLists"
              :org="org"
              :startDate="start_date"
              :endDate="end_date"
              class="mr-3"
            />
            <P32DayByStaffReport
              :style="{width: '225px'}"
              :staff="selectedUser.label"
              :bookLists="bookLists"
              :org="org"
              :startDate="start_date"
              :endDate="end_date"
              class="mr-3"
            />
            <P32DayByDateReport
              :style="{width: '225px'}"
              :staff="selectedUser.label"
              :bookLists="bookLists"
              :org="org"
              :startDate="start_date"
              :endDate="end_date"
              class="mr-3"
            />
            <ReceiveBillReportLQ310 :style="{width: '225px'}" :yearTh="yearTh" :paidLists="bookLists" :imgBase64="imgSignBase64"
                                    :imgLogoBase64="imgLogoBase64" :org="org"
                                    class="mr-4"/>
            <SingleReceiveBillReportLQ310 :style="{width: '225px'}" :yearTh="yearTh" :paidLists="selected" :imgBase64="imgSignBase64"
                                          :imgLogoBase64="imgLogoBase64" :org="org"
                                          class="mr-4"/>
          </div>
        </div>
        <div v-if="!isAuditor" class="vx-row mb-4">
          <div class="vx-col md:w-2/5 sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-2/5 w-full">
                <span>เลขที่ใบเสร็จ</span>
              </div>
              <div class="vx-col sm:w-3/5 w-full">
                <vs-input
                  type="number"
                  class="w-full"
                  v-model="number"
                  placeholder="เลขที่ใบเสร็จ (ตัวเลขเท่านั้น)"
                />
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/2 xs:w-1/1 w-full">
            <vs-button  type="filled" color="primary" @click="createNumber()"
              >สร้างเลขที่ใบเสร็จ</vs-button
            >
          </div>
          <div class="vx-col md:w-3/5 sm:w-1/3 xs:w-1/1 w-full mt-6">
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full">
                <span>กระดาษ</span>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input-number min="-100" :step="1" label="ซ้าย ขวา:" v-model="x"/>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input-number min="-100" :step="1" label="บน ล่าง:" v-model="y"/>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-button color="primary" @click="savePaperSetup()">บันทึก</vs-button>
              </div>
            </div>
          </div>
        </div>
        <vs-table
          ref="table"
          noDataText="ไม่พบข้อมูล"
          multiple
          v-model="selected"
          :data="bookLists"
          class="table-custom mt-5"
        >
          <template slot="thead">
            <vs-th>ลำดับที่</vs-th>
            <vs-th>รหัสลูกค้า</vs-th>
            <vs-th>ชื่อสกุล</vs-th>
            <vs-th>รอบบิล</vs-th>
            <vs-th>วันที่ชำระเงิน</vs-th>
            <vs-th>ผู้รับเงิน</vs-th>
            <vs-th>เลขที่ใบเสร็จ</vs-th>
            <vs-th>รวมเงิน</vs-th>
            <vs-th>คำสั่ง</vs-th>
          </template>
          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                <vs-td>
                  <p class="product-name font-medium">{{ index + 1 }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">{{ tr.B03 || "-" }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">{{ tr.M04 || "-" }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">{{ tr.CB04 }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">
                    {{ tr.B24 }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">{{ tr.B09 }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">{{ tr.B02 }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium">
                    {{ tr.sum || "-" }}
                  </p>
                </vs-td>
                <vs-td>
                  <feather-icon
                    icon="CalendarIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="editData(tr, index, 'date')"
                  />
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="editData(tr, index, 'bill')"
                  />
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import PDFReport from "../pdf/P32DayReport.vue";
import P32DayReportSortByBillNo from "../pdf/P32DayReportSortByBillNo.vue";
import P32DayByStaffReport from "../pdf/P32DayByStaffReport.vue";
import P32DayByDateReport from "../pdf/P32DayByDateReport.vue";
import PDFVatReport from "../pdf/P32DayVatReport.vue";
import moduleConfig from "@/store/config/moduleConfig.js";
import PDFBillReport from "../pdf/BillReport.vue";
import PDFBillReportExcel from "../pdf/BillReportExcel.vue";
import BillReportXL from "../pdf/BillReportXL.vue";
import SingleBillReportXL from "../pdf/SingleBillReportXL.vue";
import SingleBillReport from "../pdf/SingleBillReport.vue";
import ReceiveBillReportLQ310 from "../pdf/ReceiveBillReportLQ310.vue";
import SingleReceiveBillReportLQ310 from "../pdf/SingleReceiveBillReportLQ310.vue";
import moduleArea from "@/store/area/moduleArea.js";
import moduleUser from "@/store/user/moduleUser.js";
import moduleBookList from "@/store/booklist/moduleBookList.js";
import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
import DataViewSidebar from "./ReportP32ViewSidebar.vue";
import {formatNumber, formatFloat} from "../pdf/assets/utils";
export default {
  components: {
    Datepicker,
    vSelect,
    P32DayReportSortByBillNo,
    PDFReport,
    PDFVatReport,
    P32DayByStaffReport,
    P32DayByDateReport,
    PDFBillReport,
    DataViewSidebar,
    PDFBillReportExcel,
    BillReportXL,
    SingleBillReport,
    SingleBillReportXL,
    ReceiveBillReportLQ310,
    SingleReceiveBillReportLQ310
  },
  data() {
    return {
      isAuditor: false,
      x: 0,
      y: 0,
      selectedArea: { label: "ทั้งหมด", value: "all" },
      language: "th",
      languages: lang,
      start_date: new Date(),
      end_date: new Date(),
      year: "",
      yearTh: "",
      number: "",
      bookLists: [],
      selected: [],
      exceptAbove: [],
      exceptBelow: [],
      selectedUser: { label: "ทั้งหมด", value: "all", data: "" },
      selectedBill: { label: "รายการทั้งหมด", value: "all", data: "" },
      selectedBillConfig: "",
      selectedExceptAboveBillConfig: {},
      selectedExceptBelowBillConfig: {},
      sidebarData: {},
      type: '',
      addNewDataSidebar: false,
      indexEdit: "",
      imgSignBase64: '',
      imgLogoBase64: '',
      baseUrl: 'http://188.166.177.25/',
      createNumberAll: false,
      notice:'***ห้ามลืมเด็ดขาด ผู้ปฎิบัติงานจะต้องกำหนดรอบส่งเงินทุกวัน*** ***การรับชำระเงินผิดพลาด  ตัดยอดส่งเงินผิดจะต้องชดเงินตัวเองออกไปก่อนนะเพราะไม่สามารถแก้ไขได้*** ***การเพื่มหรือยกเลิกสมาชิกจะต้องทำหลังจากเปลี่ยนรอบบิลเท่านั่น***'
    };
  },
  computed: {
    org() {
      return this.$store.state.config.item;
    },
    areas() {
      let options = [];
      this.$store.state.area.items.forEach(function(item) {
        options.push({
          label: item.CB02  + ' (' + item.CB04+ ')',
          value: item.CB01
        });
      });
      options.unshift({
        label: "ทั้งหมด",
        value: "all"
      });
      return options;
    },
    billConfigs() {
      let options = [];
      this.$store.state.billConfigs.items.forEach(function(item) {
        options.push({
          label: item.CB02  + ' (' + item.CB04+ ')',
          value: item.CB01,
          data: item
        });
      });
      options.unshift({
        label: "ทั้งหมด",
        value: "",
        data: ""
      });
      return options;
    },
    exceptBill() {
      let options = [];
      this.$store.state.billConfigs.items.forEach(function(item) {
        options.push({
          label: item.CB02  + ' (' + item.CB04+ ')',
          value: item.CB02,
          data: item
        });
      });
      return options;
    },
    users() {
      let options = [];
      this.$store.state.user.items.forEach(function(item) {
        options.push({
          label: item.userFullname,
          value: item.userFullname,
          data: item
        });
      });
      options.unshift({
        label: "ทั้งหมด",
        value: "all",
        data: ""
      });
      return options;
    },
    bills() {
      let options = [];
      options.unshift({
        label: "เฉพาะที่ยังไม่พิมพ์",
        value: "",
        data: ""
      });
      options.unshift({
        label: "รายการทั้งหมด",
        value: "all",
        data: ""
      });
      return options;
    }
  },
  methods: {
    savePaperSetup() {
      localStorage.setItem('paper_x', this.x);
      localStorage.setItem('paper_y', this.y);
    },
    async init() {
      if (localStorage.getItem('receive_no') != null)
        this.number = localStorage.getItem('receive_no');
      if(localStorage.getItem('paper_x')!=null)
        this.x = localStorage.getItem('paper_x');
      if(localStorage.getItem('paper_y')!=null)
        this.y = localStorage.getItem('paper_y');
      this.selectedUser.label = "ทั้งหมด";
      let start = moment(this.start_date).format("YYYY/MM/DD");
      let end = moment(this.end_date).format("YYYY/MM/DD");
      const bill_except = this.$store.state.billConfigs.items[this.$store.state.billConfigs.items.length-1];
      const bill_except_below = this.$store.state.billConfigs.items[0];
      this.selectedExceptAboveBillConfig = {
        label: bill_except.CB02  + ' (' + bill_except.CB04+ ')',
        value: bill_except.CB01,
        data: bill_except
      };
      this.selectedExceptBelowBillConfig = {
        label: bill_except_below.CB02  + ' (' + bill_except_below.CB04+ ')',
        value: bill_except_below.CB01,
        data: bill_except_below
      };
      const payload = {
        startDate: start,
        endDate: end
      };
      this.getBookLists(payload);
      const url = await this.$store.state.config.item.report_image_url;
      if(url !== '' && url !== undefined && url !== null) {
        this.imgSignBase64 = await this.getDataUri(this.baseUrl + url);
      }
      const logoUrl = await this.$store.state.config.item.logo_image_url;
      if(logoUrl !== '' && logoUrl !== undefined && logoUrl !== null) {
        this.imgLogoBase64 = await this.getDataUri(this.baseUrl + logoUrl);
      }
    },
    getDataUri(url) {
      return new Promise(resolve => {
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        image.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(this, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          resolve(canvas.toDataURL("image/png"));
        };
        image.src = url;
      })
    },
    formatTextDate(text){
      if(text !== '' && text !== undefined && text !== 'Invalid date' && text !== 'NaN'){
        const split = text.split(' ')
        if(split.length === 1) {
          const dateFormat = moment(text).format("DD/MM/YYYY");
          let date = ''
          if(dateFormat === 'Invalid date') {
            date = text.split('/');
          } else {
            date = dateFormat.split('/');
          }
          if(date.length === 1) {
            return '-'
          } else {
            let year = ''
            if(date[2].toString().length === 11) {
              year = parseInt(date[2]) + 543;
            } else {
              year = parseInt(date[2]) + 543;
            }
            try {
              return date[0] + '/' + date[1] + '/' + year;
            }catch (e) {
              return text;
            }
          }
        } else {
          let date = split[0].split('/');
          if(date.length === 1) {
            return '-'
          } else {
            let year = ''
            if(date[2].toString().length === 11) {
              year = parseInt(date[2]) + 543;
            } else {
              year = parseInt(date[2]) + 543;
            }
            try {
              return date[0] + '/' + date[1] + '/' + year;
            }catch (e) {
              return text;
            }

          }
        }
      }else{
        return '-'
      }
    },
    setComma(value) {
      let word = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return word;
    },
    setDateFormat(value) {
      try {
        if(value !== '' && value !== undefined && value !== 'Invalid date' && value !== 'NaN'){
          const dateNow = moment(value).format("DD/MM/YYYY");
          if(dateNow === 'Invalid date') {
            return '-'
          } else {
            const dateSplit = dateNow.split("/");
            const year = parseInt(dateSplit[2]) + 543;
            const date = dateSplit[0] + "/" + dateSplit[1] + "/" + year;
            return date;
          }
        } else {
          return '-'
        }
      }catch (e) {
        return value
      }
    },
    formatBill(value) {
      let bill = this.$store.state.billConfigs.items.filter((item) => {
        return item.CB01 === value
      })
      if (bill.length === 0){
        return '';
      }else{
        return bill[0].CB04;
      }
    },
    editData(data, index, type) {
      this.sidebarData = data;
      this.indexEdit = index;
      this.type = type;
      this.addNewDataSidebar = true;
    },
    toggleDataSidebar(data) {
      if (data === undefined || data === "" || data === null) {
        this.addNewDataSidebar = false;
      } else {
        this.bookLists[this.indexEdit].B24 = data.B24;
        this.addNewDataSidebar = false;
      }
    },
    submitForm() {
      this.$vs.loading();
      let start = moment(this.start_date).format("YYYY/MM/DD");
      let end = moment(this.end_date).format("YYYY/MM/DD");
      if(this.org.date_range === true){
        end = moment(this.end_date).format("YYYY/MM/DD");
      }
      const payload = {};
      payload.startDate = start;
      payload.endDate = end;
      if (this.selectedBillConfig !== "") {
        payload.B01 = this.selectedBillConfig;
      }
      if (this.selectedUser.value !== "all") {
        payload.B09 = this.selectedUser.value;
      }
      if (this.selectedArea.value !== "all") {
        payload.M02 = this.selectedArea.value;
      }
      if (this.selectedBill.value !== "all") {
        payload.B02 = this.selectedBill.value;
      }
      // if(this.createNumberAll) {
      //   payload.sortBy = 'B02'
      // }
      this.getBookLists(payload);
      this.createNumberAll = false
    },
    async getBookLists(payload) {
      let exceptAbove = this.exceptAbove
      let exceptBelow = this.exceptBelow
      await this.$store.dispatch("bookLists/getBookListByP32", payload).then(res => {
        if (res.status === 200) {
          this.$vs.loading.close();
          let response = res.data;
          let array = [];
          for (let i = 0; i < response.length; i++) {
            let data = response[i];
            data.B24_raw = response[i].B24
            data.B24 = this.setDateFormat(response[i].B24)
            data.B17 = this.formatTextDate(response[i].B17)
            data.B19 = this.formatTextDate(response[i].B19)
            const BA11 = formatFloat(response[i].BA11)
            const BA12 = formatFloat(response[i].BA12)
            let BA13 = 0.0
            if(parseFloat(response[i].BA13) > 0 ) BA13 = (BA11 + BA12) * 0.07
            const BA14 = parseFloat(response[i].BA14)
            const BA15 = parseFloat(response[i].BA15)
            const sum = BA11 + BA12 + BA13 + BA14 + BA15
            data.BA11 = BA11
            data.BA12 = BA12
            data.BA13 = formatFloat(BA13)
            data.sum = formatNumber(sum)
            data.CB04 = this.formatBill(response[i].B01)
            data.Org01 = this.org.Org01
            data.Org02 = this.org.Org02
            data.Org03 = this.org.Org03
            data.Org04 = this.org.Org04
            data.Org05 = this.org.Org05
            array.push(data)
          }
          this.bookLists = array;
          if (exceptAbove.length > 0) {
            this.bookLists = this.bookLists.filter(item =>
              exceptAbove.includes(item.B01)
            );
          }
          if (exceptBelow.length > 0) {
            this.bookLists = this.bookLists.filter(item =>
              exceptBelow.includes(item.B01)
            );
          }
          this.$vs.loading.close();
        } else {
          this.bookLists = [];
          this.$vs.loading.close();
        }
      })
      .catch(error => {
        this.bookLists = [];
        this.$vs.loading.close();
      });
    },
    setUser(item) {
      this.selectedUser = item;
      this.$vs.loading();
      let start = moment(this.start_date).format("YYYY/MM/DD");
      let end = moment(this.end_date).format("YYYY/MM/DD");
      if(this.org.date_range === true){
        end = moment(this.end_date).format("YYYY/MM/DD");
      }
      const payload = {};
      payload.startDate = start;
      payload.endDate = end;
      if (this.selectedBillConfig !== "") {
        payload.B01 = this.selectedBillConfig;
      }
      if (this.selectedUser.value !== "all") {
        payload.B09 = this.selectedUser.value;
      }
      if (this.selectedArea.value !== "all") {
        payload.M02 = this.selectedArea.value;
      }
      this.getBookLists(payload);
    },
    setBill(item) {
      this.selectedBill = item;
      let start = moment(this.start_date).format("YYYY/MM/DD");
      let end = moment(this.end_date).format("YYYY/MM/DD");
      if(this.org.date_range === true){
        end = moment(this.end_date).format("YYYY/MM/DD");
      }
      const payload = {};
      payload.startDate = start;
      payload.endDate = end;
      if (this.selectedBillConfig !== "") {
        payload.B01 = this.selectedBillConfig;
      }
      if (this.selectedUser.value !== "all") {
        payload.B09 = this.selectedUser.value;
      }
      if (this.selectedArea.value !== "all") {
        payload.M02 = this.selectedArea.value;
      }
      if (this.selectedBill.value !== "all") {
        payload.B02 = this.selectedBill.value;
      }
    },
    setBillConfig(item) {
      this.selectedBillConfig = item.value;
      this.year = item.data.CB03;
      this.yearTh = item.data.CB04;
    },
    setExceptAboveBillConfigs(item) {
      let options = [];
      this.$store.state.billConfigs.items.forEach(function(data) {
        if (parseInt(data.CB02) >= parseInt(item.data.CB02))
          options.push(data.CB01);
      });
      this.exceptAbove = options;
    },
    setExceptBelowBillConfigs(item) {
      let options = [];
      this.$store.state.billConfigs.items.forEach(function(data) {
        if (parseInt(data.CB02) <= parseInt(item.data.CB02))
          options.push(data.CB01);
      });
      this.exceptBelow = options;
    },
    handleSelected(tr) {
      // console.log('tr', tr);
      // console.log('selected', this.selected)
    },
    async createNumber() {
      this.createNumberAll = false
      if(this.number === '') {
        this.warningDialog();
        return;
      }
      if (this.bookLists.length === 0) {
        this.failDialog();
        return;
      }
      for (let i = 0; i < this.bookLists.length; i++) {
        let num = parseInt(this.number) + i;
        this.bookLists[i].B02 = num.toString();
        const body = {
          _id: this.bookLists[i]._id,
          B02: num.toString()
        }
        await this.$store.dispatch("bookLists/updateItemNumber", body).then((res) => {
          if (res.status !== 200) {
            this.failDialog();
            return;
          }
        }).catch((error) => {
          this.failDialog();
          return;
        });
      }
      this.createNumberAll = true
      this.successDialog();
    },
    warningDialog() {
      this.$vs.dialog({
        type: "alert",
        color: "warning",
        title: `คำเตือน`,
        text: "กรุณากรอกเลขที่ใบเสร็จ",
        acceptText: "ปิด"
      });
    },
    failDialog() {
      this.$vs.dialog({
        type: "alert",
        color: "danger",
        title: `ผิดพลาด`,
        text: "ไม่มีเลขที่ใบเสร็จให้สร้าง",
        acceptText: "ปิด"
      });
    },
    successDialog() {
      this.$vs.dialog({
        type: "alert",
        color: "success",
        title: `สำเร็จ`,
        text: "สร้างเลขที่ใบเสร็จสำเร็จ",
        acceptText: "ปิด"
      });
    }
  },
  async created() {
    const user = JSON.parse(sessionStorage.getItem('vuex'));
    const position = user.owner.user.user.positionname;
    if(position === 'ผู้ตรวจสอบบัญชี') {
      this.isAuditor = true
    }
    this.$vs.loading();
    if (!moduleArea.isRegistered) {
      this.$store.registerModule("area", moduleArea);
      moduleArea.isRegistered = true;
    }
    if (!moduleConfig.isRegistered) {
      this.$store.registerModule("config", moduleConfig);
      moduleConfig.isRegistered = true;
    }
    if (!moduleBillConfig.isRegistered) {
      this.$store.registerModule("billConfigs", moduleBillConfig);
      moduleBillConfig.isRegistered = true;
    }
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("user", moduleUser);
      moduleUser.isRegistered = true;
    }
    if (!moduleBookList.isRegistered) {
      this.$store.registerModule("bookLists", moduleBookList);
      moduleBookList.isRegistered = true;
    }
    await this.$store.dispatch("config/fetchDataItem");
    await this.$store.dispatch("billConfigs/getDataListMenuItems");
    await this.$store.dispatch("user/fetchDataListItems");
    await this.$store.dispatch("area/fetchDataListItems");
    setTimeout(() => {
      this.$emit('input', this.init())
    }, 2000)
  }
};
</script>

<style lang="scss">
.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-export {
  padding: 0.75rem 3.35rem;
}
.text-position {
  text-align: right;
}
.table-custom .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  background: #8794a2;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}
.vs--searchable .vs__dropdown-toggle,
.v-select .vs__dropdown-toggle .vs__search,
.vdp-datepicker input {
  cursor: pointer;
}

@media screen and (max-width: 420px) {
  .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-export {
    padding: 0.75rem 2.35rem;
  }
  .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).vs-button-border.btn-edit {
    padding: 0.75rem 1rem;
  }
  .text-position {
    text-align: center;
  }
}
</style>
