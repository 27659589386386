<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์รายงานแยกตามผู้เก็บ</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  const fs = require('fs');
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatFloat, formatNumber} from "./assets/utils";
  export default {
    props: ['staff', 'bookLists', 'org', 'startDate', 'endDate'],
    components: {
      jsPDF,
    },
    name: "P32Day",
    data() {
      return {
        villageNo: '4',
        nameVillageNo: 'บ้านเหล่าคราม(04)',
        area: '04'
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      groupBy(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'danger',
            title: `ผิดพลาด`,
            text: 'ไม่มีรายชื่อผู้ที่ชำระเงินแล้ว',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      dateFormat(value) {
        if (value && value !== '-') {
          const datetime = new Date(value);
          datetime.setFullYear(datetime.getFullYear());
          return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
        }
        return value;
      },
      generatePDF() {
        let fileName = 'รายงาน ป.32 ประจำวัน';
        const title = this.staff;
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const bookLists  = this.bookLists;
        const org  = this.org;
        const dateStart = moment(this.startDate).format('DD/MM/YYYY');
        const dateStartSplit = dateStart.split('/');
        const startYear = parseInt(dateStartSplit[2]) + 543;
        const startDate = dateStartSplit[0] + '/' + dateStartSplit[1] + '/' + startYear;

        const dateEnd = moment(this.endDate).format('DD/MM/YYYY');
        const dateEndSplit = dateEnd.split('/');
        const endYear = parseInt(dateEndSplit[2]) + 543;
        const endDate = dateEndSplit[0] + '/' + dateEndSplit[1] + '/' + endYear;
        const date = this.currentThaiDate()
        const area = this.area;
        const nameVillageNo = this.nameVillageNo;
        // let BA11Sum = 0;
        // let BA12Sum = 0;
        // let BA13Sum = 0;
        // let BA14Sum = 0;
        // let BA15Sum = 0;
        // let allSum = 0;
        // let array = [];
        let group = this.groupBy(bookLists, 'B09');
        let newGroup = [];
        Object.keys(group).forEach(function(key) {
          let data = [];
          let areaGroup = group[key].reduce(function(rv, x) {
            (rv[x['M02']] = rv[x['M02']] || []).push(x);
            return rv;
          }, {});
          let sumBill = 0;
          Object.keys(areaGroup).forEach(function(key) {
            let sumBA11 = 0.0;
            let sumBA12 = 0.0;
            let sumBA13 = 0.0;
            let sumBA14 = 0.0;
            let sumBA15 = 0.0;
            areaGroup[key].forEach(function(key) {
              let vat = 0.0
              if(parseFloat(key['BA13']) !== 0){
                vat = (formatFloat(key['BA11']) + formatFloat(key['BA12'])) * 0.07
              }
              sumBA11 = sumBA11 + formatFloat(key['BA11'])
              sumBA12 = sumBA12 + formatFloat(key['BA12'])
              sumBA13 = sumBA13 + vat
              sumBA14 = sumBA14 + formatFloat(key['BA14'])
              sumBA15 = sumBA15 + formatFloat(key['BA15'])
              sumBill++;
            });
            data.push({area : areaGroup[key][0]['M02'],sumBA11 : sumBA11,sumBA12 : sumBA12,sumBA13 : sumBA13,sumBA14 : sumBA14,sumBA15 : sumBA15})
          });
          // newGroup.push({ staff_name : key , data : this.groupBy(group[key],'M02')});
          data.sort((a, b) => a['area'] - b['area']);
          newGroup.push({ staff_name : key , data : data, sumBill: sumBill});
        });
        for (let i = 0; i < newGroup.length; i++) {
          let BA11Sum = 0;
          let BA12Sum = 0;
          let BA13Sum = 0;
          let BA14Sum = 0;
          let BA15Sum = 0;
          let allSum = 0;
          let array = [];
          for (let j = 0; j < newGroup[i].data.length; j++) {
            const bookLists = newGroup[i].data[j];
            const sum = bookLists.sumBA11 + bookLists.sumBA12 + bookLists.sumBA13 + bookLists.sumBA14 + bookLists.sumBA15;
            array.push([
              j + 1,
              bookLists.area,
              bookLists.sumBA11.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              bookLists.sumBA12.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              bookLists.sumBA13.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              bookLists.sumBA14.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              bookLists.sumBA15.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
              sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
            ]);
            BA11Sum += parseFloat(bookLists.sumBA11);
            BA12Sum += parseFloat(bookLists.sumBA12);
            BA13Sum += parseFloat(bookLists.sumBA13);
            BA14Sum += parseFloat(bookLists.sumBA14);
            BA15Sum += parseFloat(bookLists.sumBA15);
            allSum += parseFloat(sum);
          }
          let sumBA111213 = BA11Sum + BA12Sum + BA13Sum;
          let sumBA1112131415 = BA11Sum + BA12Sum + BA13Sum + BA14Sum + BA15Sum;
          array.push([
            ' ',
            ' ',
            BA11Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            BA12Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            BA13Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            BA14Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            BA15Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            allSum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
          ]);
          array.push([' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']);
          array.push([{ content: '.....................................', colSpan: 4, rowSpan: 7, styles: {valign: 'bottom', halign: 'center'} }, { content: 'ได้รับชำระบิล', colSpan: 3}, { content: newGroup[i].sumBill + ' ฉบับ', styles: {halign: 'right'} }]);
          array.push([{ content: 'ได้รับเงินค่าน้ำ(ค่าน้ำ + ค่าธรรมเนียม + ภาษี)', colSpan: 3}, { content: sumBA111213.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' บาท', styles: {halign: 'right'} }]);
          array.push([{ content: 'ได้รับเงินค่าบำบัดน้ำเสีย', colSpan: 3}, { content: BA14Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' บาท', styles: {halign: 'right'} }]);
          array.push([{ content: 'ได้รับเงินค่ากำจัดขยะ', colSpan: 3}, { content: BA15Sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' บาท', styles: {halign: 'right'} }]);
          array.push([{ content: 'ได้รับเงินรวมจำนวน', colSpan: 3}, { content:  sumBA1112131415.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' บาท', styles: {halign: 'right'} }]);
          array.push([{ content: ' ', colSpan: 4 }]);
          array.push([{ content: '.....................................', colSpan: 4, styles: { halign: 'center'} }]);
          array.push([{ content: '(.....................................)', colSpan: 4, styles: { halign: 'center'} }, { content: '(.....................................)', colSpan: 4, styles: { halign: 'center'} }]);
          array.push([{ content: 'ผู้ส่งเงิน', colSpan: 4, styles: { halign: 'center'} }, { content: 'ผู้รับเงิน', colSpan: 4, styles: { halign: 'center'} }]);

          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'bold');
          doc.setFontSize(12);
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 12 },
            margin: {left: 8, top: 40, bottom: 20},
            theme: 'plain',
            head: [['ลำดับที่', 'เขต', 'ค่าน้ำ', 'ค่าธรรมเนียม', 'ภาษี', 'ค่าบำบัดฯ', 'ค่าขยะ', 'รวมทั้งสิ้น']],
            body: array,
            didDrawPage: function (data) {
              // Header
              doc.setFontSize(18);
              doc.text(org.Org01 , doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
              doc.text(org.Org03, doc.internal.pageSize.getWidth() / 2, 18, {align: 'center'});
              doc.setFontSize(16);
              doc.text('บัญชีประจำตัวผู้เก็บเงิน ป.32 ประจำวันที่ ' + startDate + ' - ' + endDate, doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
              doc.text('พนักงานเก็บเงิน ' + newGroup[i].staff_name, doc.internal.pageSize.getWidth() / 2, 36, {align: 'center'});
              // Footer
            },
          });
          if (i != newGroup.length - 1) {
            doc.addPage();
          }
        }
        // doc.setProperties({
        //   title: `report${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1 ; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save('รายงานป.32ประจำวันแยกตามผู้เก็บ-' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
   }
</script>

