<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">ใบเสร็จ Excel</vs-button>
</template>

<script>
  import * as XLSX from "xlsx";
  const fs = require('fs');
  import moment from "moment";

  export default {
    name: "BillReport",
    components: {
    },
    props: ['yearTh', 'bookLists', 'imgBase64'],
    data() {
      return {

      }
    },
    methods: {
      date(value) {
        let word = value.split(' ');
        return word[0];
      },
      setDateFormat(value) {
        try {
          const dateNow = moment(value).format("DD/MM/YYYY");
          const dateSplit = dateNow.split("/");
          const year = parseInt(dateSplit[2]) + 543;
          const date = dateSplit[0] + "/" + dateSplit[1] + "/" + year;
          return date;
        }catch (e) {
          return value
        }
      },
      formatTextDate(text){
        let dateArr0 = text.split('/');
        let day = dateArr0[0];
        let month = dateArr0[1];
        let year = 0;
        if(dateArr0[2].length === 2){
          year = parseInt(dateArr0[2]) + 2543;
        }else{
          year = parseInt(dateArr0[2]) + 543;
        }
        return day + '/' + month + '/' + year
      },
      checkData() {
        if(this.bookLists.length === 0) {
          this.warningDialog();
        } else {
          this.generatePDF();
        }
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.32 รายรับประจำวัน ',
          acceptText: 'ปิด'
        })
      },
      generatePDF() {
        let fileName = 'ใบเสร็จ.xlsx';
        let ws = XLSX.utils.json_to_sheet(this.bookLists);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    }
  }
</script>
